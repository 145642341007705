import React, { lazy, useMemo, useCallback } from 'react';
import { useRoutes, RouteObject } from 'react-router-dom'
import { useSelector } from '~contexts/UserContext';

import PATHS from './paths';
import RouteItem from './components/RouteItem';

import Suspense from '~components/Suspense';
import Dashboard from '~screens/Dashboard';
import { REGISTER_ENABLED, CONTACT_ENABLED, DONATE_ENABLED, ABOUTS_US_ENABLED } from '~constants/theme';

import styles from './styles.module.scss';

const HomePage = lazy(() => import('../../screens/Dashboard/screens/Home'));
const LoginPage = lazy(() => import('../../screens/Login'));
const RecoverPasswordPage = lazy(() => import('../../screens/RecoverPassword'));
const RegistrationPage = lazy(() => import('../../screens/Registration'));
const DeleteAccountPage = lazy(() => import('../../screens/DeleteAccount'));
const CategoriesPage = lazy(() => import('../../screens/Dashboard/screens/Categories'));
const CategoryPage = lazy(() => import('../../screens/Dashboard/screens/Category'));
const CoursePage = lazy(() => import('../../screens/Dashboard/screens/Course'));
const UserCoursesPage = lazy(() => import('../../screens/Dashboard/screens/UserCourses'));
const ActiveCoursePage = lazy(() => import('../../screens/Dashboard/screens/ActiveCourse'));
const ProfilePage = lazy(() => import('../../screens/Dashboard/screens/Profile'));
const SearchPage = lazy(() => import('../../screens/Dashboard/screens/Search'));
const Contact = lazy(() => import('../../screens/Dashboard/screens/Contact'));
const DonatePage = lazy(() => import('../../screens/Dashboard/screens/Donate'));
const AboutUsPage = lazy(() => import('../../screens/Dashboard/screens/AboutUs'));
const PrivacyPage = lazy(() => import('../../screens/Dashboard/screens/Privacy'));
const NotFoundPage = lazy(() => import('../../screens/Dashboard/screens/NotFound'));

const visibleRoute = (isVisible: boolean, routeProps :RouteObject ) => {
  if (!isVisible) return {}

  return routeProps
}

function Routes() {
  const user = useSelector(state => state.user);


  const routes = useMemo<RouteObject[]>(() => [
    {
      path: PATHS.home,
      element: <Dashboard />,
      children: [
        {
          index: true,
          element: <RouteItem Component={HomePage} />
        },
        {
          path: PATHS.categories,
          element: <RouteItem Component={CategoriesPage} />
        },
        {
          path: PATHS.category,
          element: <RouteItem Component={CategoryPage} />
        },
        {
          path: PATHS.course,
          element: <RouteItem Component={CoursePage} />
        },
        {
          path: PATHS.userCourse,
          children: [
            {
              index: true,
              element: <RouteItem Component={UserCoursesPage} />
            },
            {
              path: PATHS.activeCourse,
              element: <RouteItem Component={ActiveCoursePage} />
            },
            {
              path: '*',
              element: <NotFoundPage />,
            },
          ]
        },
        {
          path: PATHS.profile,
          element: <RouteItem Component={ProfilePage} />
        },
        {
          path: PATHS.search,
          element: <RouteItem Component={SearchPage} />
        },
        visibleRoute(
          CONTACT_ENABLED, 
          {
            path: PATHS.contact,
            element: <RouteItem Component={Contact} />
          },
        ),
        visibleRoute(
          DONATE_ENABLED, 
          {
            path: PATHS.donate,
            element: <RouteItem Component={DonatePage} />
          },
        ),
        visibleRoute(
          ABOUTS_US_ENABLED, 
          {
            path: PATHS.aboutUs,
            element: <RouteItem Component={AboutUsPage} />
          },
        ),
        {
          path: PATHS.privacy,
          element: <RouteItem Component={PrivacyPage} />
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
      ]
    },
    {
      path: PATHS.login,
      element: <RouteItem Component={LoginPage} redirectTo={user ? PATHS.home : undefined}/>
    },
    {
      path: PATHS.recoverPassword,
      element: <RouteItem Component={RecoverPasswordPage} />
    },
    visibleRoute(
      REGISTER_ENABLED,
      {
        path: PATHS.registration,
        element: <RouteItem Component={RegistrationPage} />
      },
    ),
    visibleRoute(
      REGISTER_ENABLED,
      {
        path: PATHS.deleteAccount,
        element: <RouteItem Component={DeleteAccountPage} />
      },
    ),
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ], [])

  const routesElemet = useRoutes(routes)

  return (
    <div className={styles.container}>
      <Suspense>
        {routesElemet}
      </Suspense>
    </div>
  );
}

export default Routes;
