import { THEME } from '~constants/theme';

import { ReactComponent as icFacebook } from './assets/ic_facebook.svg';
import { ReactComponent as icWebsite } from './assets/ic_website.svg';
import { ReactComponent as icInstagram } from './assets/ic_instagram.svg';
import { ReactComponent as icTwitter } from './assets/ic_twitter.svg';

export const SOCIAL_MEDIA = [
  {
    key: 'ic_website',
    Icon: icWebsite,
    url: THEME.SOCIAL_MEDIA.WEB
  },
  {
    key: 'ic_facebook',
    Icon: icFacebook,
    url: THEME.SOCIAL_MEDIA.FACEBOOK
  },
  {
    key: 'ic_twitter',
    Icon: icTwitter,
    url: THEME.SOCIAL_MEDIA.TWITTER
  },
  {
    key: 'ic_instagram',
    Icon: icInstagram,
    url: THEME.SOCIAL_MEDIA.INSTAGRAM
  }
];
