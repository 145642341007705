import React, { ElementType } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  Component: ElementType
  redirectTo?: string;
}

function RouteItem({ redirectTo, Component }: Props) {
  return redirectTo ? <Navigate to={redirectTo} /> : <Component />;
}

export default RouteItem;
