import React from 'react';
import ReactDOM from 'react-dom';
// import { AppContainer } from 'react-hot-loader/patch';

import App from './app';
import './config/i18n';
import './scss/application.scss';
import { unregister } from './serviceWorker';

const render = () => {
  ReactDOM.render(
    // <AppContainer>
      <App />,
    // </AppContainer>,
    document.getElementById('root')
  );
};

// Render once
render();

unregister();

// // Webpack Hot Module Replacement API
// if (module.hot) {
//   module.hot.accept('./app', () => {
//     render();
//   });
// }
