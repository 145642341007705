import i18next from 'i18next';

i18next.addResources('es', 'DeleteAccountConfirmationModal', {
  title: '¿Estás seguro/a?',
  description: 'Su cuenta será dada de baja',
  warning: 'Recuerde que al eliminar su cuenta, se eliminarán permanentemente la información de su perfil (nombre, teléfono, correo electrónico, foto), el historial de uso de la aplicación y sus preferencias personales. Sin embargo, algunos datos pueden conservarse para fines de seguridad y prevención de fraudes, y se retendrán de acuerdo con los períodos especificados por la legislación aplicable.',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  unsubscribeSuccess: 'Su cuenta fue dada de baja correctamente',
  defaultError: 'Ha ocurrido un error, intente nuevamente',
});

i18next.addResources('en-US', 'DeleteAccountConfirmationModal', {
  title: 'Are you sure?',
  description: 'Your account will be terminated',
  warning: 'Please note that deleting your account will permanently delete your profile information (name, phone, email, photo), application usage history, and personal preferences. However, some data may be retained for security and fraud prevention purposes and will be retained in accordance with the periods specified by applicable law.',
  confirm: 'Confirm',
  cancel: 'Cancel',
  unsubscribeSuccess: 'Your account was successfully terminated',
  defaultError: 'An error has occurred, try again',
});