import i18next from 'i18next';

i18next.addResources('es', 'FormValidations', {
  required: 'Este campo es obligatorio',
  minLength: 'Mínimo {{count}} caracteres',
  maxLength: 'Máximo {{count}} caracteres',
  email: 'El email es inválido',
  number: 'Solo valores numéricos',
  passwordRepeat: 'No coincide con la nueva contraseña',
  passwordError: 'La contraseña ingresada no es correcta',
  phone: 'El formato de teléfono es inválido'
});

i18next.addResources('en-US', 'FormValidations', {
  required: 'This field is required',
  minLength: 'At least {{count}} characters',
  maxLength: 'Maximum {{count}} characters',
  email: 'Invalid email',
  number: 'Numbers only',
  passwordRepeat: "Doesn't match with the new password",
  passwordError: 'The current password is wrong',
  phone: 'The phone format is invalid'
});
