import i18next from 'i18next';

i18next.addResources('es', 'Registration', {
  title1: 'Registrate',
  title2: 'Finalizar registro',
  explanation1: 'Obtén acceso a todos los cursos y comienza a disfrutar de aprender online',
  explanation2: 'Ingresa los siguientes datos para completar tu registro',
  name: 'Nombre',
  surname: 'Apellido',
  email: 'E-mail',
  password: 'Contraseña',
  submit: 'Registrarse',
  imgAlt: 'Personas escribiendo en computadores',
  withAccount: '¿Tienes cuenta?',
  signHere: 'Ingresa aquí',
  phone: 'Teléfono',
  date: 'Fecha de nacimiento (opcional)',
  country: 'País',
  breakExplanation: 'Datos de tu iglesia/ministerio',
  church: 'Nombre de iglesia/ministerio',
  denomination: 'Denominación',
  role: 'Rol iglesia/ministerio',
  btnSend: 'Finalizar',
  errorMessage: 'Error al procesar la solicitud',
  accessing: 'Al registrarte, aceptas nuestras',
  privacyPolicies: 'políticas de privacidad'
});

i18next.addResources('en-US', 'Registration', {
  title1: 'Sign up',
  title2: 'Finish registration',
  explanation1: 'Get access to all courses and start enjoying learning online',
  explanation2: 'Enter the following information to complete your registration',
  name: 'First name',
  surname: 'Last name',
  email: 'E-mail',
  password: 'Password',
  submit: 'Register',
  imgAlt: 'People writing on computers',
  withAccount: 'Already have an account?',
  signHere: 'Login here',
  phone: 'Phone number',
  date: 'Birthdate (optional)',
  country: 'Country',
  breakExplanation: 'Info. about your church/ministry',
  church: 'Church/ministry name',
  denomination: 'Denomination',
  role: 'Role in church/ministry',
  btnSend: 'Finish',
  errorMessage: 'Error processing the request',
  accessing: '* By accessing you accept our',
  privacyPolicies: 'privacy policies'
});
