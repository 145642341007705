import i18next from 'i18next';

i18next.addResources('es', 'Course', {
  courseDescription: 'Descripción del curso',
  courseMaterials: 'Este curso incluye',
  lessonMaterials: 'Esta lección incluye',
  startCourse: 'Iniciar curso',
  downloadable: 'Material adicional',
  activeCourse: 'Continuar curso',
  exclusiveLessons: '{{number}} lecciones exclusivas',
  withoutResults: 'No hay resultados',
  seeCourses: 'Ver cursos'
});

i18next.addResources('en-US', 'Course', {
  courseDescription: 'Course description',
  courseMaterials: 'This course includes',
  lessonMaterials: 'This lesson includes',
  startCourse: 'Start course',
  downloadable: 'Additional resources',
  activeCourse: 'Continue course',
  exclusiveLessons: '{{number}} exclusive lessons',
  withoutResults: 'No results',
  seeCourses: 'See courses'
});
