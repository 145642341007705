import { actionCreators } from '~app/contexts/UserContext/actions';
import { getCurrentUser } from '~services/AuthServices';

import api from './api';

export const authSetup = dispatch => {
  const currentUser = getCurrentUser();
  if (currentUser?.token) {
    dispatch(actionCreators.setUser(currentUser));
    api.setHeader('Authorization', `Bearer ${currentUser.token}`);
  }
};
