import React from 'react';
import { Outlet } from 'react-router';

import AppHeader from '~components/App/AppHeader';
import AppFooter from '~components/App/AppFooter';
import ResetScroll from '~components/Routes/components/ResetScroll';

function Dashboard() {
  return (
    <>
      <ResetScroll />
      <AppHeader />
      <Outlet />
      <AppFooter />
    </>
  );
}

export default Dashboard;
