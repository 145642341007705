import { User, Credentials } from '~interfaces/authInterfaces';

export enum ActionTypes {
  SET_USER = 'SET_USER',
  RESET_USER = 'RESET_USER',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

interface SetUser {
  type: ActionTypes.SET_USER;
  payload: User;
}

interface ResetUser {
  type: ActionTypes.RESET_USER;
}

interface Login {
  type: ActionTypes.LOGIN;
  payload: Credentials;
}

interface Logout {
  type: ActionTypes.LOGOUT;
}

export type Action = SetUser | ResetUser | Login | Logout;

export const actionCreators = {
  setUser: (user: User): SetUser => ({ type: ActionTypes.SET_USER, payload: user }),
  resetUser: (): ResetUser => ({ type: ActionTypes.RESET_USER }),
  login: (credentials: Credentials): Login => ({ type: ActionTypes.LOGIN, payload: credentials }),
  logout: (): Logout => ({ type: ActionTypes.LOGOUT })
};
