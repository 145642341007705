import i18next from 'i18next';

import { THEME } from '~constants/theme';

i18next.addResources('es', 'AppFooter', {
  copyright: `© 2022 ${THEME.APP_TITLE_NAME_SHORT} All rights reserved.`,
  contact: '',
  privacyPolicy: 'Política de Privacidad',
  altFooterLogo: `${THEME.APP_TITLE_NAME_SHORT} logo`,
  madeWith: 'Made with ❤️ by Antorcha Digital'
});

i18next.addResources('en-US', 'AppFooter', {
  copyright: `© 2022 ${THEME.APP_TITLE_NAME_SHORT} All rights reserved.`,
  contact: 'An initiative of The Grace Institute',
  privacyPolicy: 'Privacy Policy',
  altFooterLogo: `${THEME.APP_TITLE_NAME_SHORT} logo`,
  madeWith: 'Made with ❤️ by Antorcha Digital'
});
