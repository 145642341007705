import i18next from 'i18next';

i18next.addResources('es', 'RecoverPassword', {
  title: 'Recuperar contraseña',
  descriptionStep1: 'Ingrese su e-mail y recibirá instrucciones para cambiar su contraseña',
  descriptionStep2: 'Ingrese el código de 6 dígitos que le enviamos a su e-mail (chequear en spam)',
  descriptionStep3: 'Ingrese su nueva contraseña',
  btnTextStep1: 'Enviar',
  btnTextStep2: 'Confirmar',
  btnTextStep3: 'Guardar',
  btnTextStep4: 'Ingresar',
  email: 'Email',
  password: 'Contraseña',
  imgAlt: 'Recuperar contraseña',
  errorApi: 'Error al procesar la solicitud, intentelo nuevamente',
  errorUserNotExist: 'El e-mail ingresado no existe',
  errorCodeNotValid: 'El código es inválido',
  errorTooManyRetriesCode: 'Limite de intentos superado. Por favor genere un nuevo código',
  successPasswordMessage: 'La contraseña ha sido actualizada con éxito'
});

i18next.addResources('en-US', 'RecoverPassword', {
  title: 'Recover password',
  descriptionStep1: 'Enter your email and you will receive instructions to change your password',
  descriptionStep2: 'Enter the 6-digit code that we sent you to your e-mail (check in spam)',
  descriptionStep3: 'Enter the new password',
  btnTextStep1: 'Send',
  btnTextStep2: 'Confirm',
  btnTextStep3: 'Save',
  btnTextStep4: 'Enter',
  email: 'Email',
  password: 'Password',
  imgAlt: 'Recover password',
  errorApi: 'Error al procesar la solicitud, intentelo nuevamente',
  errorUserNotExist: "The provided e-mail doesn't exist",
  errorCodeNotValid: 'The code is invalid',
  errorTooManyRetriesCode: 'Attempt limit exceeded. Please generate a new code',
  successPasswordMessage: 'The password has been updated successfully'
});
