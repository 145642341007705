import i18next from 'i18next';

i18next.addResources('es', 'Profile', {
  title: 'Mi perfil',
  imgEdit: 'Editar imagen de perfil',
  changePassword: 'Cambiar contraseña',
  saveChanges: 'Guardar cambios',
  requestSuccess: 'Cambios actualizados correctamente',
  passwordSuccess: 'Contraseña actualizada correctamente',
  passwordError: 'Error al actualizar la contraseña',
  avatarPreview: 'Previsualización de la imagen',
  save: 'Guardar',
  oldPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  repeatNewPassword: 'Confirmar contraseña',
  changePasswordTitle: 'Cambiar contraseña',
  changePasswordText: 'Ingrese los siguientes datos para cambiar su contraseña',
  birthDate: 'Fecha de nacimiento'
});

i18next.addResources('en-US', 'Profile', {
  title: 'My profile',
  imgEdit: 'Edit profile picture',
  changePassword: 'Change password',
  saveChanges: 'Save changes',
  requestSuccess: 'Changes updated successfully',
  passwordSuccess: 'Password updated successfully',
  passwordError: 'Error updating the password',
  avatarPreview: 'Profile picture preview',
  save: 'Save',
  oldPassword: 'Current password',
  newPassword: 'New password',
  repeatNewPassword: 'Confirm password',
  changePasswordTitle: 'Change password',
  changePasswordText: 'Enter the following information to change your password',
  birthDate: 'Birthdate'
});
