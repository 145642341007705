import i18next from 'i18next';

i18next.addResources('es', 'DeleteAccount', {
  title: 'Dar de baja tu cuenta',
  description: 'Nos tomamos muy en serio la privacidad y la seguridad de tus datos. Si deseas eliminar tu cuenta, por favor ingresa tus credenciales a continuación. Nuestro equipo se asegurará de que tu solicitud sea procesada de manera rápida y segura.',
  aclaration: 'Recuerde que al eliminar su cuenta, se eliminarán permanentemente la información de su perfil (nombre, teléfono, correo electrónico, foto), el historial de uso de la aplicación y sus preferencias personales. Sin embargo, algunos datos pueden conservarse para fines de seguridad y prevención de fraudes, y se retendrán de acuerdo con los períodos especificados por la legislación aplicable.',
  email: 'E-mail',
  password: 'Contraseña',
  unsubscribe: 'Dar de baja',
});

i18next.addResources('en-US', 'DeleteAccount', {
  title: 'Unsubscribe your account',
  description: 'We take the privacy and security of your data very seriously. If you wish to delete your account, please enter your credentials below. Our team will ensure that your request is processed quickly and securely.',
  aclaration: 'Please note that deleting your account will permanently delete your profile information (name, phone, email, photo), application usage history, and personal preferences. However, some data may be retained for security and fraud prevention purposes and will be retained in accordance with the periods specified by applicable law.',
  email: 'E-mail',
  password: 'Password',
  unsubscribe: 'Unsubscribe',
  areYouSure: 'Are you sure?',
});