import i18next from 'i18next';

i18next.addResources('es', 'ActiveCourse', {
  saveChanges: 'Guardar cambios',
  updateAnswersSuccess: 'Tus respuestas se guardaron con éxito',
  updateAnswersError: 'Tus respuestas no fueron guardadas',
  questionsTitleModal: 'Preguntas de Aplicación'
});

i18next.addResources('en-US', 'ActiveCourse', {
  saveChanges: 'Save changes',
  updateAnswersSuccess: 'Your answers were saved successfuly',
  updateAnswersError: 'Your answers were not saved',
  questionsTitleModal: 'Application Questions'
});
