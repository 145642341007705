import i18next from 'i18next';

i18next.addResources('es', 'LessonItem', {
  lessonTitle: '{{number}}. {{title}}',
  minutes: '{{minutes}} Min.'
});

i18next.addResources('en-US', 'LessonItem', {
  lessonTitle: '{{number}}. {{title}}',
  minutes: '{{minutes}} Min.'
});
