import i18next from 'i18next';

i18next.addResources('es', 'Category', {
  availableCourses: 'Cursos disponibles',
  noData: 'No hay cursos disponibles',
  seeCategories: 'Ver categorías'
});

i18next.addResources('en-US', 'Category', {
  availableCourses: 'Available courses',
  noData: 'No courses available',
  seeCategories: 'See categories'
});
