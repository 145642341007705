import i18next from 'i18next';

import { THEME } from '~constants/theme';

i18next.addResources('es', 'AppHeader', {
  categories: 'Categorías',
  courses: 'Mis cursos',
  donate: 'Donar',
  contact: 'Contacto',
  aboutUs: 'Sobre Nosotros',
  sign: 'Ingresar',
  altHeaderLogo: `${THEME.APP_TITLE_NAME_SHORT} logo`,
  altSearch: 'Buscar',
  altClose: 'Cerrar',
  profile: 'Mi perfil',
  closeSession: 'Cerrar sesión',
  searchPlaceholder: 'Buscar cursos'
});

i18next.addResources('en-US', 'AppHeader', {
  categories: 'Categories',
  courses: 'My courses',
  donate: 'Donate',
  contact: 'Contact',
  aboutUs: 'About Us',
  sign: 'Sign in',
  altHeaderLogo: `${THEME.APP_TITLE_NAME_SHORT} logo`,
  altSearch: 'Search',
  altClose: 'Close',
  profile: 'My profile',
  closeSession: 'Sign out',
  searchPlaceholder: 'Search courses'
});
