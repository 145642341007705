import React, { useEffect, useReducer } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import '../scss/application.scss';
import 'react-international-phone/style.css';

import { reducer as userReducer, INITIAL_STATE } from '~contexts/UserContext/reducer';
import { Context } from '~contexts/UserContext';
import { apiSetup } from '~config/api';
import { authSetup } from '~config/auth';
import { setTheme } from '~constants/theme';

import Routes from './components/Routes';

function App() {
  const [userState, userDispatch] = useReducer(userReducer, INITIAL_STATE);
  useEffect(() => {
    apiSetup();
    authSetup(userDispatch);
    setTheme();
  }, []);

  return (
    <Context.Provider value={{ state: userState, dispatch: userDispatch }}>
      <Router>
        <Routes />
      </Router>
    </Context.Provider>
  );
}

export default App;
