import i18next from 'i18next';

import { THEME } from '~constants/theme';

i18next.addResources('es', 'Home', {
  altHero: 'Plataforma de e-learning',
  altMobile: 'Descarga la aplicación',
  title: 'La plataforma virtual de interacción y estudios bíblicos en Latinoamérica',
  latestCourses: 'Últimos cursos',
  titleApplication: 'Descarga ya la App',
  appStore: 'App store',
  playStore: 'Play Store',
  lineBg: 'Línea separadora de contenido',
  mainDescription:
    'Equípate en el estudio de la Palabra de Dios por medio de nuestra plataforma de estudios bíblicos digitales. Accederás a materiales de video y de lectura para realizar tu estudio de la Palabra de Dios de una nueva forma, más dinámica y profundamente teológica.',
  appDescription: `Con la aplicación de ${THEME.APP_TITLE_NAME_SHORT} podrá obtener todos los materiales necesarios para realizar sus cursos de estudio bíblico, con los beneficios de contar con ellos en su celular, en cualquier momento y lugar.`
});

i18next.addResources('en-US', 'Home', {
  altHero: 'E-learning platform',
  altMobile: 'Download our app',
  title: "A virtual platform that provides Biblical studies to help church leaders interact with God's Word",
  latestCourses: 'Latest courses',
  titleApplication: 'Download the App now',
  appStore: 'App Store',
  playStore: 'Play Store',
  lineBg: 'Content line separator',
  mainDescription:
    'Equip yourself to study the Word of God through our digital Bible study platform. You will access video and reading materials to carry out your study of the Word of God in a new, more dynamic and deeply theological way.',
  appDescription: `With the ${THEME.APP_TITLE_NAME_SHORT} application, you will be able to obtain all the necessary resources to take your Bible study courses, with the benefits of having them on your cell phone, at any time and place.`
});
