import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

import paths from '~components/Routes/paths';
import { THEME, SHOW_MADE_WITH } from '~constants/theme';

import { SOCIAL_MEDIA } from './constants';
import styles from './styles.module.scss';

const AppFooter = () => (
  <footer className={`row ${styles.container}`}>
    <div className={`row center middle ${styles.item}`}>
      <Link to={paths.home}>
        <img
          src={THEME.APP_LOGO}
          className={styles.appLogo}
          alt={`${i18next.t('AppFooter:altFooterLogo')}`}
        />
      </Link>
    </div>
    <div className={`column center middle ${styles.item} ${styles.middle}`}>
      <span className="base-text m-bottom-2 text-center">{i18next.t('AppFooter:contact')}</span>
      <span className="base-text m-bottom-2 text-center">{i18next.t('AppFooter:copyright')}</span>
      <Link to={paths.privacy}>{i18next.t('AppFooter:privacyPolicy')}</Link>
      {SHOW_MADE_WITH && (
        <a
          className="caption-text m-top-5 text-center"
          href="https://www.antorchadigital.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {i18next.t('AppFooter:madeWith')}
        </a>
      )}
    </div>
    <ul className={`row center ${styles.item} ${styles.last}`}>
      {SOCIAL_MEDIA.map(
        ({ Icon, key, url }) =>
          url && (
            <li className={`row center middle ${styles.linkItem}`} key={key}>
              <a className={styles.link} href={url} target="_blank" rel="noopener noreferrer">
                <Icon />
              </a>
            </li>
          )
      )}
    </ul>
  </footer>
);

export default AppFooter;
