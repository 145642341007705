import i18next from 'i18next';

i18next.addResources('es', 'ContactUsForm', {
  fullName: 'Nombre y Apellido',
  country: 'País',
  phone: 'Télefono de contacto',
  phonePlaceHolder: '54 911 23879439',
  email: 'Email',
  emailPlaceHolder: 'nombre@email.com',
  church: 'Iglesia',
  isShepherdOrLeader: '¿Eres pastor o líder de tu congregación?',
  no: 'No',
  yes: 'Si',
  comment: 'Comentario',
  commentPlaceHolder: 'Escriba aquí su comentario',
  send: 'Enviar',
  requestSuccess: 'Se ha enviado la solicitud correctamente',
  defaultError: 'Ha ocurrido un error, intente nuevamente'
});

i18next.addResources('en-US', 'ContactUsForm', {
  fullName: '',
  country: '',
  phone: '',
  phonePlaceHolder: '',
  email: '',
  emailPlaceHolder: '',
  church: '',
  isShepherdOrLeader: '',
  no: '',
  yes: '',
  comment: '',
  commentPlaceHolder: '',
  send: '',
  requestSuccess: '',
  defaultError: ''
});
