import i18next from 'i18next';

i18next.addResources('es', 'UserCourses', {
  notAvailableCourses: 'No tienes cursos activos',
  seeCourses: 'Ver cursos disponibles',
  altNoData: 'No tienes cursos actualmente',
  title: 'Mis cursos'
});

i18next.addResources('en-US', 'UserCourses', {
  notAvailableCourses: 'You do not have active courses',
  seeCourses: 'See available courses',
  altNoData: "You don't have active courses",
  title: 'My courses'
});
