import i18next from 'i18next';

i18next.addResources('es', 'CourseItemHighlight', {
  altCoursePoster: 'poster del curso',
  altGenericPoster: 'poster genérico'
});

i18next.addResources('en-US', 'CourseItemHighlight', {
  altCoursePoster: 'Course poster',
  altGenericPoster: 'Generic poster'
});
