import { Nullable } from '~utils/types';
import { User } from '~interfaces/authInterfaces';

import { Action, ActionTypes } from './actions';

export interface UserState {
  user: Nullable<User>;
}

export const INITIAL_STATE = {
  user: null
};

export const reducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case ActionTypes.SET_USER: {
      return { ...state, user: action.payload };
    }
    case ActionTypes.RESET_USER: {
      return { ...state, user: null };
    }
    default: {
      return state;
    }
  }
};
