import i18next from 'i18next';

i18next.addResources('es', 'Login', {
  login: 'Ingresar',
  loginExplanation: 'Ingresa a la plataforma y comienza a disfrutar de aprender online',
  enter: 'Iniciar sesión',
  forgotPassword: '¿Olvidaste tu contraseña?',
  email: 'E-mail',
  password: 'Contraseña',
  noAccount: '¿No tienes cuenta? ',
  createAccount: 'Registrate aquí',
  invalidCredentials: 'Email y/o contraseña incorrecto/s',
  defaultError: 'Ha ocurrido un error, intente nuevamente'
});

i18next.addResources('en-US', 'Login', {
  login: 'Sign in',
  loginExplanation: 'Enter the platform and start enjoying online learning',
  enter: 'Login',
  forgotPassword: 'Forgot your password?',
  email: 'E-mail',
  password: 'Password',
  noAccount: "Don't have an account yet? ",
  createAccount: 'Register here',
  invalidCredentials: 'Email or password invalid',
  defaultError: 'An error has occurred, try again'
});
