import i18next from 'i18next';

i18next.addResources('es', 'CourseItem', {
  lessons: '{{count}} lecciones',
  altGenericCover: 'cover genérico'
});

i18next.addResources('en-US', 'CourseItem', {
  lessons: '{{count}} lessons',
  altGenericCover: 'Generic cover'
});
