import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation()
  const [currentLocation] = useState(location);
  useEffect(() => {
    if (currentLocation.pathname !== location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [currentLocation.pathname, location.pathname]);

  return null;
};

export default ScrollToTop;
