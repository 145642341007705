import { create } from 'apisauce';
import { camelcaseSerializer, upperCamelcaseSerializer } from '~utils/serializer';

export const STATUS_CODES = {
  unauthorized: 401,
  ok: 200,
  clientError: 409
};

const api = create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000
});

export const baseURL = process.env.REACT_APP_API_BASE_URL;

export const apiSetup = () => {
  api.addResponseTransform(response => {
    if (response.data) response.data = camelcaseSerializer.serialize(response.data);
  });

  api.addRequestTransform(request => {
    if (request.data) request.data = upperCamelcaseSerializer.serialize(request.data);
    if (request.params) request.params = upperCamelcaseSerializer.serialize(request.params);
  });  
};

export default api;
