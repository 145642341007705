import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import i18next from 'i18next';

import { useDispatch } from '~contexts/UserContext';
import { actionCreators } from '~contexts/UserContext/actions';
import { removeCurrentUser } from '~services/AuthServices';
import path from '~components/Routes/paths';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
}

const NavDropdown = React.forwardRef(({ isOpen, onClose }: Props, ref: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(actionCreators.resetUser());
    removeCurrentUser();
    onClose(false);
    navigate(path.home);
  };

  return (
    <ul ref={ref} className={cx('column', styles.container, { [styles.open]: isOpen })}>
      <li className={styles.item}>
        <Link className={cx('subtitle fw-normal', styles.link)} to={path.profile}>
          {i18next.t('AppHeader:profile')}
        </Link>
      </li>
      <li className={styles.item}>
        <button type="button" className={cx('subtitle fw-normal', styles.link)} onClick={handleLogout}>
          {i18next.t('AppHeader:closeSession')}
        </button>
      </li>
    </ul>
  );
});

export default NavDropdown;
