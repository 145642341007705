import i18next from 'i18next';

i18next.addResources('es', 'NotFound', {
  title: 'No pudimos encontrar esta página',
  goToHome: 'Ir a la página principal'
});

i18next.addResources('en-US', 'NotFound', {
  title: "We couldn't find this page.",
  goToHome: 'Go to main page'
});
