import i18next from 'i18next';

import '~config/i18n';

export default {
  home: i18next.t('Routes:home') as string,
  categories: i18next.t('Routes:categories') as string,
  category: i18next.t('Routes:category') as string,
  course: i18next.t('Routes:course') as string,
  login: i18next.t('Routes:login') as string,
  recoverPassword: i18next.t('Routes:recoverPassword') as string,
  registration: i18next.t('Routes:registration') as string,
  profile: i18next.t('Routes:profile') as string,
  activeCourse: i18next.t('Routes:activeCourse') as string,
  userCourse: i18next.t('Routes:userCourse') as string,
  search: i18next.t('Routes:search') as string,
  privacy: i18next.t('Routes:privacy') as string,
  donate: i18next.t('Routes:donate') as string,
  contact: i18next.t('Routes:contact') as string,
  aboutUs: i18next.t('Routes:aboutUs') as string,
  deleteAccount: i18next.t('Routes:deleteAccount') as string
};
