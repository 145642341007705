import i18next from 'i18next';

i18next.addResources('es', 'AboutUs', {
  title1: 'Sobre Nosotros',
  title2: 'Contactanos',
  welcomeDescriptionLink: 'Conozca más acerca de el Instituto de la Gracia',
  welcomeDescription1:
    'Agathos es una iniciativa de The Grace Institute (El Instituto de la Gracia) cuyo objetivo es entrenar, equipar y preparar a líderes cristianos alrededor del mundo para la obra del ministerio.',
  welcomeDescription2:
    'Su objetivo es fomentar la formación de comunidades de aprendizaje bíblico brindando herramientas adaptables a los diversos contextos donde intenta servir. Entre ellas, nuestra plataforma cuenta con cursos profundos de Biblia y Teología que animan a la práctica en la vida, el ministerio y la misión del cristiano en donde Dios le ha puesto.',
  welcomeDescription3: 'Súmate con tu iglesia o tu grupo a la aventura de aprender juntos la Palabra de Dios',
  contactUsDescription:
    'Solicita más información contactándote con nosotros. Conoce cómo prepararte para moderar un grupo de Agathos donde quiera que te encuentres.',
  commingSoonDescription: 'Proximamente cursos y capacitaciones para moderadores de grupos'
});

i18next.addResources('en-US', 'AboutUs', {
  title1: 'About Us',
  title2: 'Contact Us',
  welcomeDescriptionLink: '',
  welcomeDescription1: '',
  welcomeDescription2: '',
  welcomeDescription3: '',
  contactUsDescription: '',
  commingSoonDescription: ''
});
