import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import cx from 'classnames';
import i18next from 'i18next';

import icSearchWhite from '~app/assets/ic_search_white.svg';
import icClose from '~app/assets/ic_close.svg';
import paths from '~components/Routes/paths';
import { THEME } from '~constants/theme';

import { MAIN_MENU_ITEMS } from '../../constants';
import Nav from '../Nav';

import styles from './styles.module.scss';

const ENTER_KEY_CODE = 13;
const DELAY_SET_VALUE = 500;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const NavResponsive = ({ isOpen, onClose }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState('');

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      setValue('');
    }, DELAY_SET_VALUE);
  }, [onClose]);

  useEffect(() => {
    handleClose()
  }, [location, handleClose]);

  const handleInput = (e: any) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === ENTER_KEY_CODE && value) {
      navigate(`${paths.search}?query=${value}`);
    }
  };

  return (
    <header className={cx('column', styles.container, { [styles.open]: isOpen })}>
      <div className="row middle space-between m-bottom-4">
        <Link to={paths.home} className="m-right-1">
          <img
            src={THEME.APP_LOGO_WHITE}
            className={styles.appLogo}
            alt={`${i18next.t('AppHeader:altHeaderLogo')}`}
          />
        </Link>
        <button type="button" className={styles.closeBtn} onClick={handleClose}>
          <img src={icClose} alt={`${i18next.t('AppHeader:altClose')}`} />
        </button>
      </div>
      <div className={`m-bottom-2 ${styles.inputSearch}`}>
        <img src={icSearchWhite} alt={`${i18next.t('AppHeader:altSearch')}`} />
        <input
          className="subtitle fw-normal white-text"
          type="text"
          role="search"
          value={value}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          placeholder={`${i18next.t('AppHeader:searchPlaceholder')}`}
        />
      </div>
      <nav className={`row center middle item-2 ${styles.smHide}`}>
        <Nav pathname={location.pathname} items={MAIN_MENU_ITEMS} isResponsive />
      </nav>
    </header>
  );
};

export default NavResponsive;
