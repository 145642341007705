import { ApiResponse } from 'apisauce';

import { User, Credentials, ISignInSelect, ISignInData, IRecoverPassword } from '~interfaces/authInterfaces';
import { ApiError, Data, DataResults } from '~interfaces/globalInterfaces';

import api from '../config/api';

import LocalStorageService from './LocalStorageService';

const USER_FIELD_NAME = 'token';

export const END_POINTS = {
  auth: '/api/v1/Auth',
  getCountries: '/api/v1/Users/Countries',
  getDenominations: '/api/v1/Users/Denominations',
  getMinistries: '/api/v1/Users/Ministries',
  signIn: '/api/v1/Account/Register',
  forgetPass: '/api/v1/Account/ForgetPass',
  verifyCode: '/api/v1/Account/RecoverPass',
  recoverChangePass: '/api/v1/Account/ChangePass',
  deleteAccount: '/api/v1/Account/Profile/del',
};

export interface LoginError {
  message: string;
}

export const getSelectDataSignIn = (
  type: string
): Promise<ApiResponse<DataResults<ISignInSelect[]>, LoginError>> =>
  api.get<DataResults<ISignInSelect[]>, LoginError>(type);

export const formatUser = (userResponse: UserResponse): User => ({
  token: userResponse?.token,
  ...userResponse.user
});

export interface UserResponse {
  token: string;
  user: {
    userId: string;
    roleId: string;
    roleName: string;
    username: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
}

export const setCurrentUser = (currentUser: User) => {
  api.setHeader('Authorization', `Bearer ${currentUser.token}`);
  LocalStorageService.setValue(USER_FIELD_NAME, JSON.stringify(currentUser));
};

export const getCurrentUser = () => {
  const value = LocalStorageService.getValue(USER_FIELD_NAME);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return {};
    }
  }
  return {};
};

export const removeCurrentUser = () => {
  LocalStorageService.removeValue(USER_FIELD_NAME);
  api.deleteHeader('Authorization');
};

export const login = (credentials: Credentials): Promise<ApiResponse<UserResponse, Data<ApiError>>> => {
  const basicAuth = btoa(`${credentials.email}:${credentials.password}`);
  return api.post(END_POINTS.auth, {}, { headers: { Authorization: `Basic ${basicAuth}` } });
};

export const logout = (): Promise<ApiResponse<UserResponse, ApiError>> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve({
        ok: true,
        data: {
          token: '',
          user: {
            userId: '',
            roleId: '',
            roleName: '',
            avatar: '',
            firstName: '',
            lastName: '',
            username: ''
          }
        },
        problem: null,
        originalError: null
      });
    }, 1000); // eslint-disable-line no-magic-numbers
  });

export const signIn = (data: ISignInData): Promise<ApiResponse<UserResponse, Data<ApiError>>> =>
  api.post(END_POINTS.signIn, data);

export const forgetPass = (
  data: Omit<IRecoverPassword, 'code' | 'newPassword'>
): Promise<ApiResponse<Data<{}>, Data<ApiError>>> => api.post(END_POINTS.forgetPass, data);

export const verifyCode = (
  data: Omit<IRecoverPassword, 'newPassword'>
): Promise<ApiResponse<Data<{}>, Data<ApiError>>> => api.post(END_POINTS.verifyCode, data);

export const recoverChangePass = (data: IRecoverPassword): Promise<ApiResponse<Data<{}>, Data<ApiError>>> =>
  api.post(END_POINTS.recoverChangePass, data);

export const deleteAccount = (
  token: string,
): Promise<ApiResponse<Data<{}>, Data<ApiError>>> =>
  api.delete<Data<{}>, Data<ApiError>>(
    END_POINTS.deleteAccount,
    undefined,
    { headers: { Authorization: `Bearer ${token}` } },
  );
