import i18next from 'i18next';

i18next.addResources('es', 'Search', {
  title: '{{quantity}} Resultados para "{{query}}"',
  singleTitle: '{{quantity}} Resultado para "{{query}}"',
  withoutResults: 'No hay resultados para la búsqueda'
});

i18next.addResources('en-US', 'Search', {
  title: '{{quantity}} Results for "{{query}}"',
  singleTitle: '{{quantity}} Result for "{{query}}"',
  withoutResults: 'No search results'
});
