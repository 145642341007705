import i18next from 'i18next';

i18next.addResources('es', 'SectionItem', {
  sectionNumber: 'Sección {{number}}: {{title}}',
  minutes: '{{minutes}} Min.',
  lessons: '{{count}} Lecciones',
  altExpandIcon: 'icono expandir'
});

i18next.addResources('en-US', 'SectionItem', {
  sectionNumber: 'Section {{number}}: {{title}}',
  minutes: '{{minutes}} Min.',
  lessons: '{{count}} Lessons',
  altExpandIcon: 'Expand icon'
});
