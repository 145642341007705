import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import icSearchWhite from '~app/assets/ic_search_white.svg';
import icSearchBlack from '~app/assets/ic_search_black.svg';
import paths from '~components/Routes/paths';
import { useSelector } from '~contexts/UserContext';
import { THEME } from '~constants/theme';
import useVisible from '~hooks/useVisible';

import NavResponsive from './components/NavResponsive';
import Nav from './components/Nav';
import NavDropdown from './components/NavDropdown';
import Searcher from './components/Searcher';
import { MAIN_MENU_ITEMS } from './constants';
import styles from './styles.module.scss';

enum headerStyles {
  default,
  translucent
}

const AppHeader = () => {
  const { pathname } = useLocation();
  const user = useSelector(state => state.user);
  const [headerStyle, setHeaderStyle] = useState(headerStyles.translucent);
  const [pageOffset, setPageOffset] = useState(0);
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSearcherOpen, setIsSearcherOpen] = useState(false);

  const handleScroll = () => {
    setPageOffset(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setHeaderStyle(
      pageOffset === 0 && pathname === paths.categories ? headerStyles.translucent : headerStyles.default
    );
  }, [pageOffset, pathname]);

  const conditionalClassNames = () => ({
    [styles.default]: headerStyle === headerStyles.default,
    [styles.main]: headerStyle === headerStyles.translucent
  });

  return (
    <header className={cx('row space-betweens', styles.container, conditionalClassNames())}>
      <div className="row start middle item-1">
        <Link to={paths.home}>
          <img
            src={headerStyle === headerStyles.default ? THEME.APP_LOGO : THEME.APP_LOGO_WHITE}
            className={styles.appLogo}
            alt={`${i18next.t('AppHeader:altHeaderLogo')}`}
          />
        </Link>
      </div>
      <nav className={`row center middle item-2 ${styles.smHide}`}>
        <Nav
          pathname={pathname}
          items={MAIN_MENU_ITEMS}
          itemClassName={cx('text-center', styles.text, conditionalClassNames())}
        />
      </nav>
      <div className={`row end middle item-1 ${styles.btnContainer}`}>
        <button
          className="row middle center btn btn-link-outline m-left-3"
          type="button"
          onClick={() => setIsSearcherOpen(!isSearcherOpen)}
        >
          <img
            src={headerStyle === headerStyles.default ? icSearchBlack : icSearchWhite}
            alt={`${i18next.t('AppHeader:altSearch')}`}
          />
        </button>
        {user ? (
          <>
            {user.avatar ? (
              <img
                className={`m-left-3 ${styles.avatar}`}
                src={user.avatar}
                alt={`${user.firstName} ${user.lastName}`}
                onClick={() => setIsVisible(!isVisible)}
              />
            ) : (
              <button
                type="button"
                className={cx('btn btn-link-primary m-left-3', styles.btnUser)}
                onClick={() => setIsVisible(!isVisible)}
              >
                {`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}
              </button>
            )}
          </>
        ) : (
          <Link className={cx('btn btn-link-primary m-left-3', styles.button)} to={paths.login}>
            {i18next.t('AppHeader:sign')}
          </Link>
        )}
        <NavDropdown isOpen={isVisible} onClose={setIsVisible} ref={ref} />
      </div>
      <Searcher isOpen={isSearcherOpen} onClose={setIsSearcherOpen} />
      <button type="button" className={styles.collapseBtn} onClick={() => setIsNavOpen(true)}>
        <span className={styles.btnLine} />
      </button>
      <NavResponsive isOpen={isNavOpen} onClose={() => setIsNavOpen(false)} />
    </header>
  );
};

export default AppHeader;
