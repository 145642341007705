/* eslint-disable max-lines */
import i18next from 'i18next';

import { THEME } from '~constants/theme';

// TODO translate it
i18next.addResources('es', 'Privacy', {
  title: 'POLÍTICA DE PRIVACIDAD',
  effective: 'Fecha de vigencia: 2021-01-01',
  introduction: '1. Introducción',
  welcome: `Bienvenido a <b> ${THEME.APP_TITLE_NAME}</b>`,
  paragraph1: `<b>${THEME.APP_TITLE_NAME}</b> (“nosotros”, o “nuestro”) opera en <b>${THEME.APP_URL}</b> (en lo sucesivo denominado <b>“Servicio”</b>).`,
  paragraph2: `Nuestra Política de privacidad rige su visita a <b>${THEME.APP_URL}</b> y explica cómo recopilamos, salvaguardamos y divulgamos la información que resulta de su uso de nuestro Servicio.`,
  paragraph3:
    'Usamos sus datos para proporcionar y mejorar el Servicio. Al utilizar el Servicio, acepta la recopilación y el uso de información de acuerdo con esta política.',
  paragraph4:
    'Nuestra Política de privacidad rige todo uso de nuestro Servicio y constituye su acuerdo con nosotros (<b>"acuerdo"</b>).',
  definitions: '2. Definiciones',
  paragraph5: `<b>SERVICIO</b> significa el sitio web ${THEME.APP_URL} operado por ${THEME.APP_TITLE_NAME}.`,
  paragraph6:
    '<b>DATOS PERSONALES</b> se refiere a los datos sobre una persona viva que puede ser identificada a partir de esos datos (o de esos y otra información que esté en nuestro poder o que pueda llegar a nuestro poder).',
  paragraph7:
    '<b>DATOS DE USO</b> son datos recopilados automáticamente, ya sea generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).',
  paragraph8:
    '<b>COOKIES</b> son pequeños archivos almacenados en su dispositivo (computadora o dispositivo móvil).',
  paragraph9:
    '<b>RESPONSABLE DEL TRATAMIENTO</b> es una persona física o jurídica que (sola o conjuntamente o en común con otras personas) determina los fines para los que y la forma en que se tratan o se tratan los datos personales. . A los efectos de esta Política de privacidad, somos un controlador de datos de sus datos.',
  paragraph10:
    '<b>PROCESADORES DE DATOS (O PROVEEDORES DE SERVICIOS)</b> significa cualquier persona física o jurídica que procese los datos en nombre del Controlador de datos. Podemos utilizar los servicios de varios proveedores de servicios para procesar sus datos de manera más efectiva.</p> <p><b>SUJETO DE DATOS</b> es cualquier individuo vivo que sea sujeto de Datos personales.',
  paragraph11:
    '<b>EL USUARIO</b> es la persona que utiliza nuestro Servicio. El Usuario corresponde al Sujeto de los Datos, que es el sujeto de los Datos Personales.',
  information: '3. Recopilación y uso de información',
  paragraph12:
    'Recopilamos varios tipos diferentes de información para diversos fines para brindarle y mejorar nuestro Servicio.',
  types: '4. Tipos de datos recopilados',
  personalData: 'Datos personales',
  usageData: 'Datos de uso',
  trackingData: 'Seguimiento de datos de cookies',
  otherData: 'Otros datos',
  paragraph13:
    'Al utilizar nuestro Servicio, es posible que le pidamos que nos proporcione cierta información de identificación personal que pueda utilizarse para contactarlo o identificarlo (<b>“Datos personales”</b>). La información de identificación personal puede incluir, pero no se limita a:',
  list1: 'Dirección de email',
  list2: 'Nombre y Apellido',
  list3: 'Número de teléfono',
  list4: 'Dirección, Pais, Estado, Provincia, Código postal, Ciudad',
  list5: 'Cookies y Datos de uso',
  paragraph14:
    'Podemos utilizar sus Datos personales para comunicarnos con usted con boletines informativos, materiales de marketing o promocionales y otra información que pueda ser de su interés. Puede optar por no recibir alguna o todas estas comunicaciones de nuestra parte siguiendo el enlace para cancelar la suscripción.',
  paragraph15:
    'También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de cualquier dispositivo (<b>“Datos de uso”</b>).',
  paragraph16:
    'Estos Datos de uso pueden incluir información como la dirección de Protocolo de Internet de su computadora (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y fecha de su visita, el tiempo que pasa en esas páginas, identificadores únicos de dispositivo y otros datos de diagnóstico.',
  paragraph17:
    'Cuando accede al Servicio con un dispositivo, estos Datos de uso pueden incluir información como el tipo de dispositivo que usa, la ID única de su dispositivo, la dirección IP de su dispositivo, el sistema operativo de su dispositivo, el tipo de navegador de Internet que usa, identificadores de dispositivos y otros datos de diagnóstico.',
  paragraph18:
    'Usamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro Servicio y tenemos cierta información.',
  paragraph19:
    'Las cookies son archivos con una pequeña cantidad de datos que pueden incluir un identificador único anónimo. Las cookies se envían a su navegador desde un sitio web y se almacenan en su dispositivo. También se utilizan otras tecnologías de seguimiento, como balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar nuestro Servicio.',
  paragraph20:
    'Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio.',
  paragraph21: 'Ejemplos de cookies que usamos:',
  list6: '<b>Cookies de sesión:</b> Usamos cookies de sesión para operar nuestro Servicio.',
  list7:
    '<b>Cookies de preferencia:</b> Utilizamos cookies de preferencia para recordar sus preferencias y diversas configuraciones.',
  list8: '<b>Cookies de seguridad:</b> Usamos cookies de seguridad por motivos de seguridad.',
  paragraph22:
    'Al utilizar nuestro Servicio, también podemos recopilar la siguiente información: sexo, edad, fecha de nacimiento, lugar de nacimiento, número de teléfono (trabajo, móvil) y otros datos.',
  useData: '5. Uso de datos',
  paragraph23: `${THEME.APP_TITLE_NAME} utiliza los datos recopilados para diversos fines:`,
  list10: 'para proporcionar y mantener nuestro Servicio;',
  list11: 'para notificarle sobre cambios en nuestro Servicio;',
  list12:
    'para permitirle participar en las funciones interactivas de nuestro Servicio cuando elija hacerlo;',
  list13: 'para brindar soporte al cliente;',
  list14: 'para recopilar análisis o información valiosa para que podamos mejorar nuestro Servicio;',
  list15: 'para monitorear el uso de nuestro Servicio;',
  list16: 'para detectar, prevenir y abordar problemas técnicos;',
  list17: 'para cumplir con cualquier otro propósito para el que lo proporcione;',
  list18:
    'para cumplir con nuestras obligaciones y hacer valer nuestros derechos derivados de cualquier contrato celebrado entre usted y nosotros, incluidos los de facturación y cobro;',
  list19:
    'para proporcionarle avisos sobre su cuenta y / o suscripción, incluidos avisos de vencimiento y renovación, instrucciones por correo electrónico, etc .;',
  list20:
    'para proporcionarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya ha comprado o sobre los que ha consultado a menos que haya optado por no recibir dicha información;',
  list21: 'de cualquier otra forma que podamos describir cuando proporcione la información;',
  list22: 'para cualquier otro propósito con su consentimiento.',
  retention: '6. Retención de datos',
  transfer: '7. Transferencia de datos',
  disclosure: '8. Divulgación de datos',
  security: '9. Seguridad de los datos',
  paragraph24:
    'Conservaremos sus Datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Retendremos y usaremos sus Datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.',
  paragraph25:
    'También conservaremos los Datos de uso con fines de análisis interno. Los datos de uso generalmente se conservan por un período más corto, excepto cuando estos datos se usan para fortalecer la seguridad o para mejorar la funcionalidad de nuestro Servicio, o estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.',
  paragraph26:
    'Su información, incluidos los Datos personales, se puede transferir a, y mantener en, computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.',
  paragraph27:
    'Su información, incluidos los Datos personales, se puede transferir a, y mantener en, computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.',
  paragraph28:
    'Si se encuentra fuera de Brasil y decide proporcionarnos información, tenga en cuenta que transferimos los datos, incluídos los Datos personales, a Brasil y los procesamos allí.',
  paragraph29:
    'Su consentimiento a esta Política de privacidad seguido de su envío de dicha información representa su aceptación de esa transferencia.',
  paragraph30: `${THEME.APP_TITLE_NAME} tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus Datos personales a una organización o país, a menos que existan controles adecuados establecidos, incluida la seguridad. de sus datos y otra información personal.`,
  paragraph31: 'Podemos divulgar información personal que recopilemos o que usted proporcione:',
  paragraph32:
    '0.1. <b>Divulgación para las fuerzas del orden.</b></p><p>En determinadas circunstancias, es posible que se nos solicite que divulguemos sus Datos personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas.</p><p>0.2. <b>Transacción comercial.</b></p><p>Si nosotros o nuestras subsidiarias estamos involucrados en una fusión, adquisición o venta de activos, sus datos personales pueden ser transferidos.</p><p>0.3. <b>Otros casos. También podemos divulgar su información:</b></p><p>0.3.1. a nuestras subsidiarias y afiliadas;</p><p>0.3.2. a contratistas, proveedores de servicios y otros terceros que utilizamos para respaldar nuestro negocio;</p><p>0.3.3. para cumplir con el propósito para el que lo proporciona;</p><p>0.3.4. con el fin de incluir el logotipo de su empresa en nuestro sitio web;</p><p>0.3.5. para cualquier otro propósito divulgado por nosotros cuando usted proporciona la información;</p><p>0.3.6. con su consentimiento en cualquier otro caso;</p><p>0.3.7. si creemos que la divulgación es necesaria o apropiada para proteger los derechos, la propiedad o la seguridad de la Compañía, nuestros clientes u otros.',
  paragraph33:
    'La seguridad de sus datos es importante para nosotros, pero recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos personales, no podemos garantizar su seguridad absoluta.',
  protection:
    '10. Sus derechos de protección de datos según el Reglamento general de protección de datos (GDPR)',
  dataProtection:
    '11. Sus derechos de protección de datos según la Ley de protección de la privacidad de California (CalOPPA)',
  dataProtection2:
    '12. Sus derechos de protección de datos según la Ley de privacidad del consumidor de California (CCPA)',
  providers: '13. Proveedores de servicio',
  paragraph34: `<p>Si es residente de la Unión Europea (UE) y el Espacio Económico Europeo (EEE), tiene ciertos derechos de protección de datos, cubiertos por GDPR.</p>
  <p>Nuestro objetivo es tomar las medidas razonables que le permitan corregir, modificar, eliminar o limitar el uso de sus Datos personales.</p>
  <p> Si desea recibir información sobre los datos personales que tenemos sobre usted y si desea que se eliminen de nuestros sistemas, envíenos un correo electrónico a <b>${THEME.APP_CONTACT_EMAIL}</b>.</p>
  <p>En determinadas circunstancias, tiene los siguientes derechos de protección de datos:</p> <br />
  <p>0.1. el derecho a acceder, actualizar o eliminar la información que tenemos sobre usted;</p>
  <p>0.2. el derecho de rectificación. Tiene derecho a que se rectifique su información si esa información es inexacta o incompleta;</p>
  <p>0.3. el derecho a oponerse. Tiene derecho a oponerse a nuestro procesamiento de sus Datos personales;</p>
  <p>0.4. el derecho de restricción. Tiene derecho a solicitar que restrinjamos el procesamiento de su información personal;</p>
  <p>0.5. el derecho a la portabilidad de datos. Tiene derecho a que se le proporcione una copia de sus Datos personales en un formato estructurado, legible por máquina y de uso común;</p>
  <p>0.6. el derecho a retirar el consentimiento. También tiene derecho a retirar su consentimiento en cualquier momento en el que dependamos de su consentimiento para procesar su información personal;</p>
  <p>Tenga en cuenta que es posible que le pidamos que verifique su identidad antes de responder a dichas solicitudes. Tenga en cuenta que es posible que no podamos proporcionar el Servicio sin algunos datos necesarios.</p>
  <p>Tiene derecho a presentar una queja ante una autoridad de protección de datos sobre nuestra recopilación y uso de sus datos personales. Para obtener más información, comuníquese con su autoridad local de protección de datos en el Espacio Económico Europeo (EEE).</p>`,
  paragraph35: `<p>CalOPPA es la primera ley estatal de la nación que exige que los sitios web comerciales y los servicios en línea publiquen una política de privacidad. El alcance de la ley se extiende mucho más allá de California para exigir que una persona o empresa en los Estados Unidos (y posiblemente el mundo) que opera sitios web que recopilan información de identificación personal de los consumidores de California publique una política de privacidad visible en su sitio web que indique exactamente la información que se recopila y aquellos personas con las que se comparte y para cumplir con esta política.</p>
  <p>De acuerdo con CalOPPA estamos de acuerdo con lo siguiente:</p>
  <p>0.1. los usuarios pueden visitar nuestro sitio de forma anónima;</p>
  <p>0.2. nuestro enlace de Política de privacidad incluye la palabra "Privacidad" y se puede encontrar fácilmente en la página de inicio de nuestro sitio web;</p>
  <p>0.3. los usuarios serán notificados de cualquier cambio en la política de privacidad en nuestra Página de Política de Privacidad;</p>
  <p>0.4. los usuarios pueden cambiar su información personal enviándonos un correo electrónico a <b>${THEME.APP_CONTACT_EMAIL}</b>.</p>
  <p>Nuestra política sobre señales de "No rastrear":</p>
  <p>Respetamos las señales de No rastrear y no rastreamos, colocamos cookies ni usamos publicidad cuando hay un mecanismo de navegador de No rastrear. No rastrear es una preferencia que puede establecer en su navegador web para informar a los sitios web que no desea que se le rastree.</p>
  <p>Puede habilitar o deshabilitar No rastrear visitando la página de Preferencias o Configuración de su navegador web.</p>`,
  paragraph36: `<p>Si usted es un residente de California, tiene derecho a saber qué datos recopilamos sobre usted, solicitar eliminar sus datos y no venderlos (compartirlos). Para ejercer sus derechos de protección de datos, puede realizar determinadas solicitudes y preguntarnos:</p> <br />
  <p><b>0.1. Qué información personal tenemos sobre usted. Si realiza esta solicitud, le responderemos:</b></p>
  <p>0.0.1. Las categorías de información personal que hemos recopilado sobre usted.</p>
  <p>0.0.2. Las categorías de fuentes de las que recopilamos su información personal.</p>
  <p>0.0.3. El propósito comercial o empresarial para recopilar o vender su información personal.</p>
  <p>0.0.4. Las categorías de terceros con los que compartimos información personal.</p>
  <p>0.0.5. Las piezas específicas de información personal que hemos recopilado sobre usted.</p>
  <p>0.0.6. Una lista de categorías de información personal que hemos vendido, junto con la categoría de cualquier otra empresa a la que se la vendimos. Si no hemos vendido su información personal, le informaremos de ese hecho.</p>
  <p>0.0.7. Una lista de categorías de información personal que hemos divulgado con un propósito comercial, junto con la categoría de cualquier otra empresa con la que la compartimos.</p>
  <p>Tenga en cuenta que tiene derecho a solicitarnos que le proporcionemos esta información hasta dos veces en un período continuo de doce meses. Cuando realiza esta solicitud, la información proporcionada puede limitarse a la información personal que recopilamos sobre usted en los 12 meses anteriores.</p>
  <p><b>0.2. Para borrar su información personal. Si realiza esta solicitud, eliminaremos la información personal que tenemos sobre usted a la fecha de su solicitud de nuestros registros y le indicaremos a cualquier proveedor de servicios que haga lo mismo. En algunos casos, la eliminación se puede lograr mediante la desidentificación de la información. Si elige eliminar su información personal, es posible que no pueda utilizar determinadas funciones que requieren su información personal para funcionar.</b></p>
  <p><b>0.3. Dejar de vender su información personal. No vendemos ni alquilamos su información personal a terceros para ningún propósito. No vendemos su información personal por consideración monetaria. Sin embargo, en algunas circunstancias, una transferencia de información personal a un tercero, o dentro de nuestra familia de empresas, sin consideración monetaria, puede considerarse una "venta" según la ley de California. Usted es el único propietario de sus datos personales y puede solicitar la divulgación o eliminación en cualquier momento.</b></p>
  <p>Si envía una solicitud para dejar de vender su información personal, dejaremos de realizar dichas transferencias.</p>
  <p>Tenga en cuenta que si nos solicita que eliminemos o dejemos de vender sus datos, puede afectar su experiencia con nosotros y es posible que no pueda participar en ciertos programas o servicios de membresía que requieren el uso de su información personal para funcionar. Pero en ningún caso lo discriminaremos por ejercer sus derechos.</p>
  <p>Para ejercer sus derechos de protección de datos de California descritos anteriormente, envíe su (s) solicitud (es) por correo electrónico: <b>${THEME.APP_CONTACT_EMAIL}</b>.</p>
  <p>Sus derechos de protección de datos, descritos anteriormente, están cubiertos por la CCPA, abreviatura de la Ley de Privacidad del Consumidor de California. Para obtener más información, visite el sitio web oficial de Información legislativa de California. La CCPA entró en vigor el 01/01/2020.</p>`,
  paragraph37:
    'Podemos emplear empresas e individuos de terceros para facilitar nuestro Servicio (<b>“Proveedores de servicios”</b>), proporcionar el Servicio en nuestro nombre, realizar servicios relacionados con el Servicio o ayudarnos a analizar cómo se utiliza nuestro Servicio.',
  paragraph38:
    'Estos terceros tienen acceso a sus Datos personales solo para realizar estas tareas en nuestro nombre y están obligados a no divulgarlos ni utilizarlos para ningún otro propósito.',
  analytics: '14. Analítica',
  paragraph39:
    'Podemos utilizar proveedores de servicios externos para monitorear y analizar el uso de nuestro Servicio.',
  ci: '15. Herramientas CI/CD',
  paragraph40:
    'Podemos utilizar proveedores de servicios de terceros para automatizar el proceso de desarrollo de nuestro Servicio.',
  behavioral: '16. Remarketing conductual',
  paragraph41:
    'Podemos utilizar los servicios de remarketing para anunciarle en sitios web de terceros después de que haya visitado nuestro Servicio. Nosotros y nuestros proveedores externos utilizamos cookies para informar, optimizar y publicar anuncios basados ​​en sus visitas anteriores a nuestro Servicio.',
  links: '17. Enlaces a otros sitios',
  paragraph42:
    'Nuestro Servicio puede contener enlaces a otros sitios que no son operados por nosotros. Si hace clic en el enlace de un tercero, se le dirigirá al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.',
  paragraph43:
    'No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros.',
  children: '18. Privacidad de los niños',
  paragraph44:
    'ONuestros Servicios no están destinados a ser utilizados por niños menores de 18 años (<b>Niño</b> or <b>Niños</b>).',
  paragraph45:
    'No recopilamos a sabiendas información de identificación personal de niños menores de 18 años. Si se da cuenta de que un niño nos ha proporcionado datos personales, comuníquese con nosotros. Si nos damos cuenta de que hemos recopilado datos personales de niños sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.',
  changes: '19. Cambios a esta política de privacidad',
  paragraph46:
    'Podemos actualizar nuestra Política de privacidad de vez en cuando. Le notificaremos de cualquier cambio publicando la nueva Política de privacidad en esta página.',
  paragraph47:
    'Le informaremos por correo electrónico y / o un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigencia y actualice la "fecha de vigencia" en la parte superior de esta Política de privacidad.',
  paragraph48:
    'Se le recomienda que revise esta Política de privacidad periódicamente para detectar cualquier cambio. Los cambios a esta Política de privacidad entran en vigencia cuando se publican en esta página.',
  contact: '20. Contact Us',
  paragraph49: `Si tiene alguna pregunta sobre esta Política de privacidad, contáctenos por correo electrónico: <b>${THEME.APP_CONTACT_EMAIL}</b>.`
});

i18next.addResources('en-US', 'Privacy', {
  title: 'PRIVACY POLICY',
  effective: 'Effective date: 2021-01-01',
  introduction: '1. Introduction',
  welcome: `Welcome to <b> ${THEME.APP_TITLE_NAME}</b>`,
  paragraph1: `<b>${THEME.APP_TITLE_NAME}</b> (“us”, “we”, or “our”) operates <b>${THEME.APP_URL}</b> (hereinafter referred to as <b>“Service”</b>).`,
  paragraph2: `Our Privacy Policy governs your visit to <b>${THEME.APP_URL}</b>, and explains how we collect, safeguard and disclose information that results from your use of our Service.`,
  paragraph3:
    'We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy.',
  paragraph4:
    'Our Privacy Policy govern all use of our Service and constitutes your agreement with us (<b>“agreement”</b>).',
  definitions: '2. Definitions',
  paragraph5: `<b>SERVICE</b> means the ${THEME.APP_URL} website operated by ${THEME.APP_TITLE_NAME}.`,
  paragraph6:
    '<b>PERSONAL DATA</b> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).',
  paragraph7:
    '<b>USAGE DATA</b> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).',
  paragraph8: '<b>COOKIES</b> are small files stored on your device (computer or mobile device).',
  paragraph9:
    '<b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.',
  paragraph10:
    '<b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p> <p><b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.',
  paragraph11:
    '<b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.',
  information: '3. Information Collection and Use',
  paragraph12:
    'We collect several different types of information for various purposes to provide and improve our Service to you.',
  types: '4. Types of Data Collected',
  personalData: 'Personal Data',
  usageData: 'Usage Data',
  trackingData: 'Tracking Cookies Data',
  otherData: 'Other Data',
  paragraph13:
    'While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (<b>“Personal Data”</b>). Personally identifiable information may include, but is not limited to:',
  list1: 'Email address',
  list2: 'First name and last name',
  list3: 'Phone number',
  list4: 'Address, Country, State, Province, ZIP/Postal code, City',
  list5: 'Cookies and Usage Data',
  paragraph14:
    'We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.',
  paragraph15:
    'We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (<b>“Usage Data”</b>).',
  paragraph16:
    'This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.',
  paragraph17:
    'When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.',
  paragraph18:
    'We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.',
  paragraph19:
    'Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.',
  paragraph20:
    'You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.',
  paragraph21: 'Examples of Cookies we use:',
  list6: '<b>Session Cookies:</b> We use Session Cookies to operate our Service.',
  list7:
    '<b>Preference Cookies:</b> We use Preference Cookies to remember your preferences and various settings.',
  list8: '<b>Security Cookies:</b> We use Security Cookies for security purposes.',
  paragraph22:
    'While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, telephone number (work, mobile) and other data.',
  useData: '5. Use of Data',
  paragraph23: `${THEME.APP_TITLE_NAME} uses the collected data for various purposes:`,
  list10: 'to provide and maintain our Service;',
  list11: 'to notify you about changes to our Service;',
  list12: 'to allow you to participate in interactive features of our Service when you choose to do so;',
  list13: 'to provide customer support;',
  list14: 'to gather analysis or valuable information so that we can improve our Service;',
  list15: 'to monitor the usage of our Service;',
  list16: 'to detect, prevent and address technical issues;',
  list17: 'to fulfil any other purpose for which you provide it;',
  list18:
    'to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;',
  list19:
    'to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;',
  list20:
    'to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;',
  list21: 'in any other way we may describe when you provide the information;',
  list22: 'for any other purpose with your consent.',
  retention: '6. Retention of Data',
  transfer: '7. Transfer of Data',
  disclosure: '8. Disclosure of Data',
  security: '9. Security of Data',
  paragraph24:
    'We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
  paragraph25:
    'We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.',
  paragraph26:
    'Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.',
  paragraph27:
    'Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.',
  paragraph28:
    'If you are located outside Brazil and choose to provide information to us, please note that we transfer the data, including Personal Data, to Brazil and process it there.',
  paragraph29:
    'Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.',
  paragraph30: `${THEME.APP_TITLE_NAME} will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.`,
  paragraph31: 'We may disclose personal information that we collect, or you provide:',
  paragraph32:
    '0.1. <b>Disclosure for Law Enforcement.</b></p><p>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p><p>0.2. <b>Business Transaction.</b></p><p>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</p><p>0.3. <b>Other cases. We may disclose your information also:</b></p><p>0.3.1. to our subsidiaries and affiliates;</p><p>0.3.2. to contractors, service providers, and other third parties we use to support our business;</p><p>0.3.3. to fulfill the purpose for which you provide it;</p><p>0.3.4. for the purpose of including your company’s logo on our website;</p><p>0.3.5. for any other purpose disclosed by us when you provide the information;</p><p>0.3.6. with your consent in any other cases;</p><p>0.3.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.',
  paragraph33:
    'The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.',
  protection: '10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)',
  dataProtection: '11. Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)',
  dataProtection2: '12. Your Data Protection Rights under the California Consumer Privacy Act (CCPA)',
  providers: '13. Service Providers',
  paragraph34: `<p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR.</p>
  <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
  <p> If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at <b>${THEME.APP_CONTACT_EMAIL}</b>.</p>
  <p>In certain circumstances, you have the following data protection rights:</p> <br />
  <p>0.1. the right to access, update or to delete the information we have on you;</p>
  <p>0.2. the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</p>
  <p>0.3. the right to object. You have the right to object to our processing of your Personal Data;</p>
  <p>0.4. the right of restriction. You have the right to request that we restrict the processing of your personal information;</p>
  <p>0.5. the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</p>
  <p>0.6. the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</p>
  <p>Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.</p>
  <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>`,
  paragraph35: `<p>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy.</p>
  <p>According to CalOPPA we agree to the following:</p>
  <p>0.1. users can visit our site anonymously;</p>
  <p>0.2. our Privacy Policy link includes the word “Privacy”, and can easily be found on the home page of our website;</p>
  <p>0.3. users will be notified of any privacy policy changes on our Privacy Policy Page;</p>
  <p>0.4. users are able to change their personal information by emailing us at <b>${THEME.APP_CONTACT_EMAIL}</b>.</p>
  <p>Our Policy on “Do Not Track” Signals:</p>
  <p>We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
  <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>`,
  paragraph36: `<p>If you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:</p> <br />
  <p><b>0.1. What personal information we have about you. If you make this request, we will return to you:</b></p>
  <p>0.0.1. The categories of personal information we have collected about you.</p>
  <p>0.0.2. The categories of sources from which we collect your personal information.</p>
  <p>0.0.3. The business or commercial purpose for collecting or selling your personal information.</p>
  <p>0.0.4. The categories of third parties with whom we share personal information.</p>
  <p>0.0.5. The specific pieces of personal information we have collected about you.</p>
  <p>0.0.6. A list of categories of personal information that we have sold, along with the category of any other company we sold it to. If we have not sold your personal information, we will inform you of that fact.</p>
  <p>0.0.7. A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.</p>
  <p>Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.</p>
  <p><b>0.2. To delete your personal information. If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate.</b></p>
  <p><b>0.3. To stop selling your personal information. We don’t sell or rent your personal information to any third parties for any purpose. We do not sell your personal information for monetary consideration. However, under some circumstances, a transfer of personal information to a third party, or within our family of companies, without monetary consideration may be considered a “sale” under California law. You are the only owner of your Personal Data and can request disclosure or deletion at any time.</b></p>
  <p>If you submit a request to stop selling your personal information, we will stop making such transfers.</p>
  <p>Please note, if you ask us to delete or stop selling your data, it may impact your experience with us, and you may not be able to participate in certain programs or membership services which require the usage of your personal information to function. But in no circumstances, we will discriminate against you for exercising your rights.</p>
  <p>To exercise your California data protection rights described above, please send your request(s) by email: <b>${THEME.APP_CONTACT_EMAIL}</b>.</p>
  <p>Your data protection rights, described above, are covered by the CCPA, short for the California Consumer Privacy Act. To find out more, visit the official California Legislative Information website. The CCPA took effect on 01/01/2020.</p>`,
  paragraph37:
    'We may employ third party companies and individuals to facilitate our Service (<b>“Service Providers”</b>), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.',
  paragraph38:
    'These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.',
  analytics: '14. Analytics',
  paragraph39: 'We may use third-party Service Providers to monitor and analyze the use of our Service.',
  ci: '15. CI/CD tools',
  paragraph40: 'We may use third-party Service Providers to automate the development process of our Service.',
  behavioral: '16. Behavioral Remarketing',
  paragraph41:
    'We may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.',
  links: '17. Links to Other Sites',
  paragraph42:
    'Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.',
  paragraph43:
    'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
  children: '18. Children’s Privacy',
  paragraph44:
    'Our Services are not intended for use by children under the age of 18 (<b>“Child”</b> or <b>“Children”</b>).',
  paragraph45:
    'We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.',
  changes: '19. Changes to This Privacy Policy',
  paragraph46:
    'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.',
  paragraph47:
    'We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.',
  paragraph48:
    'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
  contact: '20. Contact Us',
  paragraph49: `If you have any questions about this Privacy Policy, please contact us by email: <b>${THEME.APP_CONTACT_EMAIL}</b>.`
});
