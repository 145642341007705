import { init } from 'i18next';

import { THEME } from '~constants/theme';

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

init({
  lng: THEME.LANGUAGE.iso,
  initImmediate: false
});

requireAll(require.context('..', true, /i18n\.(js|ts)$/));
