import React, { Suspense } from 'react';

import Loading from '../Spinner/components/loading';

interface Props {
  children: React.ReactNode;
}

function CustomSuspense({ children }: Props) {
  return <Suspense fallback={<Loading/>}>{children}</Suspense>;
}

export default CustomSuspense;
