import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

const images = require.context('../app/assets', true);

const DEFAULT_THEME = 'TELEIOS';

enum ThemeTypes {
  BLAZE = 'BLAZE',
  TELEIOS = 'TELEIOS',
  AGATHOS = 'AGATHOS',
  ANTORCHA = 'ANTORCHA'
}

type IPicture = {
  png: string;
  webp: string;
};

interface IPictureResponsive extends IPicture {
  responsive?: IPicture;
}

type ITheme = {
  [key in ThemeTypes]: {
    APP_TITLE_NAME: string;
    APP_TITLE_NAME_SHORT: string;
    APP_URL: string;
    APP_CONTACT_EMAIL: string;
    LOGIN_IMG: string;
    RECOVER_PASSWORD_IMG: string;
    RECOVER_PASSWORD_IC_SUCCESS: string;
    APP_LOGO: string;
    APP_LOGO_WHITE: string;
    PLAY_ICON: string;
    HERO_LANDING: IPictureResponsive;
    HOME_MOBILE: IPicture;
    NO_DATA: string;
    ERROR: string;
    LANGUAGE: {
      locale: any;
      iso: string;
      formatDate: string;
    };
    IMG_BG_LINE_LANDING: string;
    META_ATTR: {
      FAVICON: string;
      THEME_COLOR: string;
      MANIFEST: string;
    };
    SOCIAL_MEDIA: {
      FACEBOOK: string | null;
      INSTAGRAM: string | null;
      TWITTER: string | null;
      WEB: string | null;
      PLAY_STORE_LINK: string;
      APP_STORE_LINK: string;
    };
  };
};

const THEMES: ITheme = {
  TELEIOS: {
    APP_TITLE_NAME: 'Teleios LATAM',
    APP_TITLE_NAME_SHORT: 'Teleios',
    APP_URL: 'https://teleioslatam.com',
    APP_CONTACT_EMAIL: 'info@teleioslatam.com',
    LOGIN_IMG: images('./teleios/img_login.svg'),
    RECOVER_PASSWORD_IMG: images('./teleios/img_recover_password.svg'),
    RECOVER_PASSWORD_IC_SUCCESS: images('./teleios/ic_password_check.svg'),
    APP_LOGO: images('./teleios/app_logo.svg'),
    APP_LOGO_WHITE: images('./teleios/app_logo_white.svg'),
    PLAY_ICON: images('./teleios/ic_play.svg'),
    HERO_LANDING: {
      png: images('./teleios/hero_img_landing.png'),
      webp: images('./teleios/hero_img_landing.webp'),
      responsive: {
        png: images('./teleios/hero_img_landing_responsive.png'),
        webp: images('./teleios/hero_img_landing_responsive.webp')
      }
    },
    HOME_MOBILE: {
      png: images('./teleios/img_mobile_section.png'),
      webp: images('./teleios/img_mobile_section.webp')
    },
    NO_DATA: images('./teleios/no_data.svg'),
    ERROR: images('./teleios/error.svg'),
    LANGUAGE: {
      locale: es,
      iso: 'es',
      formatDate: 'dd/MM/yyyy'
    },
    IMG_BG_LINE_LANDING: images('./teleios/img_background_landing.svg'),
    META_ATTR: {
      FAVICON: images('./teleios/favicon.ico'),
      THEME_COLOR: '#21B4FF',
      MANIFEST: './TELEIOS-manifest.json'
    },
    SOCIAL_MEDIA: {
      FACEBOOK: null,
      INSTAGRAM: null,
      TWITTER: null,
      WEB: null,
      PLAY_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.tgi.teleios',
      APP_STORE_LINK: 'https://apps.apple.com/us/app/teleios/id1550631355'
    }
  },
  BLAZE: {
    APP_TITLE_NAME: 'Blaze Studies',
    APP_TITLE_NAME_SHORT: 'Blaze',
    APP_URL: 'https://blazestudies.org',
    APP_CONTACT_EMAIL: 'info@blazestudies.org',
    LOGIN_IMG: images('./blaze/img_login.svg'),
    RECOVER_PASSWORD_IMG: images('./blaze/img_recover_password.svg'),
    RECOVER_PASSWORD_IC_SUCCESS: images('./blaze/ic_password_check.svg'),
    APP_LOGO: images('./blaze/app_logo.svg'),
    APP_LOGO_WHITE: images('./blaze/app_logo_white.svg'),
    PLAY_ICON: images('./blaze/ic_play.svg'),
    HERO_LANDING: {
      png: images('./blaze/hero_img_landing.png'),
      webp: images('./blaze/hero_img_landing.webp'),
      responsive: {
        png: images('./blaze/hero_img_landing_responsive.png'),
        webp: images('./blaze/hero_img_landing_responsive.webp')
      }
    },
    HOME_MOBILE: {
      png: images('./blaze/img_mobile_section.png'),
      webp: images('./blaze/img_mobile_section.webp')
    },
    NO_DATA: images('./blaze/no_data.svg'),
    ERROR: images('./blaze/error.svg'),
    LANGUAGE: {
      locale: en,
      iso: 'en-US',
      formatDate: 'MM/dd/yyyy'
    },
    IMG_BG_LINE_LANDING: images('./blaze/img_background_landing.svg'),
    META_ATTR: {
      FAVICON: images('./blaze/favicon.ico'),
      THEME_COLOR: '#E65300',
      MANIFEST: './BLAZE-manifest.json'
    },
    SOCIAL_MEDIA: {
      FACEBOOK: 'https://www.facebook.com/thegraceinstituteintl',
      INSTAGRAM: 'https://www.instagram.com/thegraceinstitute/',
      TWITTER: null,
      WEB: 'https://www.thegraceinstituteintl.com',
      PLAY_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.tgi.blaze',
      APP_STORE_LINK: 'https://apps.apple.com/us/app/blaze-studies/id1547252962'
    }
  },
  AGATHOS: {
    APP_TITLE_NAME: 'Agathos LATAM',
    APP_TITLE_NAME_SHORT: 'Agathos',
    APP_URL: 'https://www.agathoslatam.com',
    APP_CONTACT_EMAIL: 'info@agathoslatam.com',
    LOGIN_IMG: images('./agathos/img_login.svg'),
    RECOVER_PASSWORD_IMG: images('./agathos/img_recover_password.svg'),
    RECOVER_PASSWORD_IC_SUCCESS: images('./agathos/ic_password_check.svg'),
    APP_LOGO: images('./agathos/app_logo.svg'),
    APP_LOGO_WHITE: images('./agathos/app_logo_white.svg'),
    PLAY_ICON: images('./agathos/ic_play.svg'),
    HERO_LANDING: {
      png: images('./agathos/hero_img_landing.png'),
      webp: images('./agathos/hero_img_landing.webp'),
      responsive: {
        png: images('./agathos/hero_img_landing_responsive.png'),
        webp: images('./agathos/hero_img_landing_responsive.webp')
      }
    },
    HOME_MOBILE: {
      png: images('./agathos/img_mobile_section.png'),
      webp: images('./agathos/img_mobile_section.webp')
    },
    NO_DATA: images('./agathos/no_data.svg'),
    ERROR: images('./agathos/error.svg'),
    LANGUAGE: {
      locale: es,
      iso: 'es',
      formatDate: 'dd/MM/yyyy'
    },
    IMG_BG_LINE_LANDING: images('./agathos/img_background_landing.svg'),
    META_ATTR: {
      FAVICON: images('./agathos/favicon.ico'),
      THEME_COLOR: '#515e7a',
      MANIFEST: './AGATHOS-manifest.json'
    },
    SOCIAL_MEDIA: {
      FACEBOOK: null,
      INSTAGRAM: null,
      TWITTER: null,
      WEB: null,
      PLAY_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.tgi.agathos',
      APP_STORE_LINK: 'https://apps.apple.com/us/app/agathos/id6443849262'
    }
  },
  ANTORCHA: {
    APP_TITLE_NAME: 'Antorcha LATAM',
    APP_TITLE_NAME_SHORT: 'Antorcha',
    APP_URL: 'https://www.antorchadigital.com/',
    APP_CONTACT_EMAIL: 'hi@antorchadigital.com',
    LOGIN_IMG: images('./antorcha/img_login.svg'),
    RECOVER_PASSWORD_IMG: images('./antorcha/img_recover_password.svg'),
    RECOVER_PASSWORD_IC_SUCCESS: images('./antorcha/ic_password_check.svg'),
    APP_LOGO: images('./antorcha/app_logo.png'),
    APP_LOGO_WHITE: images('./antorcha/app_logo_white.png'),
    PLAY_ICON: images('./antorcha/ic_play.svg'),
    HERO_LANDING: {
      png: images('./antorcha/hero_img_landing.png'),
      webp: images('./antorcha/hero_img_landing.webp'),
      responsive: {
        png: images('./antorcha/hero_img_landing_responsive.png'),
        webp: images('./antorcha/hero_img_landing_responsive.webp')
      }
    },
    HOME_MOBILE: {
      png: images('./antorcha/img_mobile_section.png'),
      webp: images('./antorcha/img_mobile_section.webp')
    },
    NO_DATA: images('./antorcha/no_data.svg'),
    ERROR: images('./antorcha/error.svg'),
    LANGUAGE: {
      locale: es,
      iso: 'es',
      formatDate: 'dd/MM/yyyy'
    },
    IMG_BG_LINE_LANDING: images('./antorcha/img_background_landing.svg'),
    META_ATTR: {
      FAVICON: images('./antorcha/favicon.png'),
      THEME_COLOR: '#515e7a',
      MANIFEST: './ANTORCHA-manifest.json'
    },
    SOCIAL_MEDIA: {
      FACEBOOK: null,
      INSTAGRAM: null,
      TWITTER: null,
      WEB: null,
      PLAY_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.tgi.agathos',
      APP_STORE_LINK: 'https://apps.apple.com/us/app/agathos/id6443849262'
    }
  }
};

export const THEME = THEMES[(process.env.REACT_APP_THEME as ThemeTypes) || DEFAULT_THEME];

const createMeta = (name: string, content: string) => {
  const meta = document.createElement('meta');
  meta.setAttribute('name', name);
  meta.setAttribute('content', content);
  document.head.prepend(meta);
};

export const setTheme = () => {
  document.querySelector('body')?.setAttribute('data-theme', `${process.env.REACT_APP_THEME}`);
  document.title = THEME.APP_TITLE_NAME;

  const favicon = document.createElement('link');
  favicon.setAttribute('rel', 'shortcut icon');
  favicon.setAttribute('href', THEME.META_ATTR.FAVICON);
  document.head.prepend(favicon);

  const manifest = document.createElement('link');
  manifest.setAttribute('rel', 'manifest');
  manifest.setAttribute('href', THEME.META_ATTR.MANIFEST);
  document.head.prepend(manifest);

  createMeta('theme-color', THEME.META_ATTR.THEME_COLOR);
};

export const REGISTER_ENABLED = process.env.REACT_APP_SHOW_REGISTER === String(true);
export const SHOW_PHONE_FIELD = process.env.REACT_APP_SHOW_PHONE_FIELD === String(true);
export const CONTACT_ENABLED = process.env.REACT_APP_SHOW_CONTACT === String(true);
export const CAN_ENROLL_COURSE = process.env.REACT_APP_SHOW_REGISTER === String(true);
export const DONATE_ENABLED = process.env.REACT_APP_SHOW_DONATE === String(true);
export const SHOW_MADE_WITH = process.env.REACT_APP_SHOW_MADE_WITH === String(true);
export const ABOUTS_US_ENABLED = process.env.REACT_APP_SHOW_ABOUTS_US === String(true);
