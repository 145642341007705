import i18next from 'i18next';

import paths from '~components/Routes/paths';
import { CONTACT_ENABLED, DONATE_ENABLED, ABOUTS_US_ENABLED } from '~constants/theme';

export const MAIN_MENU_ITEMS = [
  {
    label: i18next.t('AppHeader:categories'),
    url: paths.categories,
    current: true,
    isVisible: true
  },
  {
    label: i18next.t('AppHeader:courses'),
    url: paths.userCourse,
    current: false,
    isVisible: true
  },
  {
    label: i18next.t('AppHeader:donate'),
    url: paths.donate,
    current: false,
    isVisible: DONATE_ENABLED
  },
  {
    label: i18next.t('AppHeader:contact'),
    url: paths.contact,
    current: false,
    isVisible: CONTACT_ENABLED
  },
  {
    label: i18next.t('AppHeader:aboutUs'),
    url: paths.aboutUs,
    current: false,
    isVisible: ABOUTS_US_ENABLED
  }
];
