import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import i18next from 'i18next';

import { useDispatch, useSelector } from '~contexts/UserContext';
import { actionCreators } from '~contexts/UserContext/actions';
import { removeCurrentUser } from '~services/AuthServices';
import path from '~components/Routes/paths';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  itemClassName?: string;
  items: {
    label: string;
    url: string;
    current?: boolean;
    isVisible?: boolean;
  }[];
  pathname: string;
  isResponsive?: boolean;
}

const Nav = ({ className, itemClassName, items, pathname, isResponsive }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const handleLogout = () => {
    dispatch(actionCreators.resetUser());
    removeCurrentUser();
    navigate(path.home);
  };

  return (
    <ul className={cx('row middle', styles.container, className)}>
      {items.map(({ label, url, isVisible }) => {
        if (!isVisible) {
          return null;
        }

        return (
          <li className={cx('row center middle m-right-2 m-left-2', styles.item)} key={url}>
            <Link
              className={cx(
                'subtitle fw-normal white-text',
                styles.link,
                { [styles.current]: pathname === url },
                itemClassName
              )}
              to={url}
            >
              {label}
            </Link>
          </li>
        );
      })}
      {isResponsive && (
        <>
          {user && (
            <li className={cx('row center middle m-right-2 m-left-2', styles.item)}>
              <Link
                className={cx(
                  'subtitle fw-normal white-text',
                  styles.link,
                  { [styles.current]: pathname === path.profile },
                  itemClassName
                )}
                to={path.profile}
              >
                {i18next.t('AppHeader:profile')}
              </Link>
            </li>
          )}
          <li className={cx('row center middle m-right-2 m-left-2', styles.item)}>
            {user ? (
              <button
                type="button"
                className={cx('subtitle fw-normal white-text', styles.link, itemClassName)}
                onClick={handleLogout}
              >
                {i18next.t('AppHeader:closeSession')}
              </button>
            ) : (
              <Link
                className={cx('subtitle fw-normal white-text', styles.link, itemClassName)}
                to={path.login}
              >
                {i18next.t('AppHeader:sign')}
              </Link>
            )}
          </li>
        </>
      )}
    </ul>
  );
};

export default Nav;
