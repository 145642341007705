import i18next from 'i18next';

import { THEME } from '~constants/theme';

i18next.addResources('es', 'Contact', {
  title: 'Contacto',
  flagName: 'Bandera {{flag}}',
  description1: `Únete y sé parte de la experiencia de estudios bíblicos y teológicos en comunidad, a través de la plataforma ${THEME.APP_TITLE_NAME_SHORT} en toda Latinoamérica.`,
  description2: 'Contáctanos en los siguientes países, a través de la Sociedad Bíblica.',
  description3:
    'Si te encuentras en otro país de Latinoamérica, puedes contactarte con nosotros, para conocer más y comenzar a estudiar.'
});

i18next.addResources('en-US', 'Contact', {
  title: 'Contact',
  flagName: 'Flag {{flag}}',
  description1: '',
  description2: '',
  description3: ''
});
