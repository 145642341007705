import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import cx from 'classnames';
import i18next from 'i18next';

import { ReactComponent as IcSearch } from '~app/assets/ic_search_white.svg';
import { ReactComponent as IcClose } from '~app/assets/ic_close.svg';
import path from '~components/Routes/paths';

import styles from './styles.module.scss';

const ENTER_KEY_CODE = 13;
const DELAY_SET_VALUE = 500;

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
}

const Searcher = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState('');

  const handleClose = useCallback(() => {
    onClose(false);
    setTimeout(() => {
      setValue('');
    }, DELAY_SET_VALUE);
  }, [onClose]);

  useEffect(() => {
    handleClose();
  }, [history, handleClose]);

  const handleInput = (e: any) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === ENTER_KEY_CODE && value) {
      navigate(`${path.search}?query=${value}`);
    }
  };

  const handleClick = () => {
    if (value) {
      navigate(`${path.search}?query=${value}`);
    }
  };

  return (
    <div className={cx(styles.container, { [styles.open]: isOpen })}>
      <IcSearch className={styles.iconSearch} onClick={handleClick} />
      <input
        className="title-section fw-normal white-text text-center full-width"
        type="text"
        role="search"
        value={value}
        placeholder={`${i18next.t('AppHeader:searchPlaceholder')}`}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
      />
      <IcClose className={styles.icon} onClick={handleClose} />
    </div>
  );
};

export default Searcher;
